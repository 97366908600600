import { Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { IMenuItem } from '@bs24/core/models/menu';
import { MenuService } from '@bs24/cms/services/menu.service';

@Component({
  selector: 'menu[menu]',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  imports: [
    NgTemplateOutlet,
    RouterLink,
    RouterLinkActive,
    NgClass,
    LowerCasePipe,
    SvgIconDirective
  ],
  standalone: true
})
export class MenuComponent {

  @Input()
  menu: IMenuItem;

  @Input()
  mode: 'section' | 'side' | 'help' | 'header';

  lang: string;


  constructor(translate: TranslateService, private cmsService: MenuService, private router: Router) {
    this.lang = translate.currentLang;
    translate.onLangChange.subscribe({
      next: data => this.lang = data.lang
    });
  }

  close() {
    this.cmsService.toggleMenu(false);
  }

  public isLinkActive(menuItem: IMenuItem): boolean {
    if (menuItem.routerLink) {
      return this.router.url.includes(menuItem.routerLink);
    }
    return false;
    /*else {
      if (menuItem.items && menuItem.items.length) { // if there are subs dig-in recursively
        const ml = menuItem.items as Array<IMenuItem>;

        return ml.some(mi => this.isLinkActive(mi));
      } else {
        return false;
      }
    }*/
  }
}
