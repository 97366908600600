<ng-template #linkTpl let-item>
  <a [routerLink]="'/' + lang + item.routerLink" [class.text-body]="mode === 'side'" class="d-flex flex-grow-1 nav-link align-items-center"  routerLinkActive="active">
    @if (item.icon) {
      <svg [size]="{w:24, h:24}" [icon]="item.icon | lowercase" class="icon fill-current me-25 flex-shrink-0" lib="menu"></svg>
    }
    <span class="item-label text-nowrap">{{ item.label }}</span>
    @if (item.badge) {
      <span class="badge rounded-pill bg-warning text-dark ms-25">{{ item.badge }}</span>
    }
    @if (item.items) {
      <svg class="expand fill-current" icon="angle-down" lib="solid"></svg>
    }
  </a>
</ng-template>

<ng-template #externalTpl let-item>
  <a [href]="item.url" class="nav-link" target="_blank">
    @if (item.icon) {
      <svg [icon]="item.icon" class="icon" lib="menu"></svg>
    }
    <span class="flex-grow-1">{{ item.label }}</span>
  </a>
</ng-template>

<ng-template #noLinkTpl let-item>
  <button (click)="item.expanded = !item.expanded" [class.active]="item.expanded" [routerLink]="item.url" class="p-link"
          routerLinkActive="active" type="button">
    <span>{{ item.label }}</span>
    @if (item.badge) {
      <span class="badge">{{ item.badge }}</span>
    }
  </button>
</ng-template>

<ng-template #itemTpl let-item>
  <div (click)="item.expanded = !item.expanded" [ngClass]="{'has-sub': item.items?.length}">
    <ng-template [ngTemplateOutletContext]="{ $implicit: item }"
                 [ngTemplateOutlet]="item.routerLink ? linkTpl: item.url ? externalTpl:noLinkTpl"></ng-template>
  </div>
</ng-template>

<ng-template #hasSubTpl let-item let-level="level">
  <ng-template [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="itemTpl"></ng-template>
  <ul [class.column]="vertical" class="navbar lev-{{level}}">
    <ng-template [ngTemplateOutletContext]="{ $implicit: item.items, level:(level+1) }"
                 [ngTemplateOutlet]="recursiveList"></ng-template>
  </ul>
</ng-template>

<ng-template #recursiveList let-items let-level="level">
  @for (item of items; track item.id) {
    <li [ngClass]="{leaf: !item.items, expanded: item?.expanded || isLinkActive(item)}" [class.mb-1]="vertical"
        class="nav-item {{item.slug}} flex-grow-1">
      <ng-template [ngTemplateOutletContext]="{ $implicit: item, level:level }"
                   [ngTemplateOutlet]="item.items?.length ? hasSubTpl:itemTpl"></ng-template>
    </li>
  }
</ng-template>

<!--<div class="menu-header align-items-center justify-content-between">
<h3 class="menu-title mb-0">{{menu?.label}}</h3>
<a [routerLink]="['/', lang, 'preferences']" class="pref icon-white d-md-none" (click)="close()">
  <svg [size]="{w:20, h:20}" class="icon-preference" icon="cog" lib="solid"></svg>
</a>
</div>-->
<ng-content select=".prepend"></ng-content>
<nav class="navbar navbar-expand mw-100 p-0"
     [class.md-bg-primary]="mode==='section'"
     [class.px-md-25]="mode==='section'"
     [class.my-25]="mode==='section'"
     [class.nav-pills]="mode==='section'"
     [class.nav-header]="mode==='header'">


  <div [class.pane]="mode==='section'"
       [class.no-bar]="mode==='section'" [class.w-100]="mode==='side'">

  @if (menu?.items) {
    <ul [class.flex-column]="mode==='side' || mode==='help'" class="navbar-nav {{mode}}"
        [class.flex-nowrap]="mode==='section' || mode==='header'"
        [class.gap-1]="mode==='section' || mode ==='header'">
      <ng-template [ngTemplateOutletContext]="{ $implicit: menu.items, level:1 }"
                   [ngTemplateOutlet]="recursiveList"></ng-template>
    </ul>
  }
  </div>
</nav>
<ng-content select=".append"></ng-content>
